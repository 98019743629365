// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// PONGO AQUI LA URL DE PRE PORQUE AWS NO ESTA DESPLEGANDO CON LAS LAS VARIABLES DE PROD

export const environment = {
  production: true,
  environmentName: 'pro',
  apiUrl: 'https://qlvyscbdm6.execute-api.eu-west-1.amazonaws.com',
  apiEnv: '/pro',
  appId: '4e793146-a0c5-42af-8647-c879e2721a85',
  tentantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

/* export const environment = {
  production: false,
  environmentName: 'int',
  apiUrl: 'http://127.0.0.1:3000',
  apiEnv: '',
  appId: '3d8799a2-fa35-4c9a-bca5-67c6ec4eacdc',
  tentantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

export const environment = {
  production: true,
  environmentName: 'pre',
  apiUrl: 'https://0wb3hxpryb.execute-api.eu-west-1.amazonaws.com',
  apiEnv: '/pro',
  appId: '5661ea41-1289-4491-b220-cb82871e23ef',
  tentantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

export const environment = {
  production: true,
  environmentName: 'pro',
  apiUrl: 'https://qlvyscbdm6.execute-api.eu-west-1.amazonaws.com',
  apiEnv: '/pro',
  appId: '4e793146-a0c5-42af-8647-c879e2721a85',
  tentantId: '5cc6c66d-ffb2-469f-9385-cda840e57836',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

*/


/*
 * URL a la API
 *  Pre : https://0wb3hxpryb.execute-api.eu-west-1.amazonaws.com
 *  Int : https://c9zc8wdj20.execute-api.eu-west-1.amazonaws.com
 *  PRO: https://d19i1tk0wlxbrm.cloudfront.net
 *
 *  AppId
 *  Pre: 5661ea41-1289-4491-b220-cb82871e23ef
 *  Int: 3d8799a2-fa35-4c9a-bca5-67c6ec4eacdc
 *  tenantId: 5cc6c66d-ffb2-469f-9385-cda840e57836
 *
 */

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
